import { Router } from '@angular/router';
import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { NavigationSelectors } from '../../state/navigation/navigation.state.selectors';
import { Observable } from 'rxjs';
import { NavigationPage } from '../../interfaces/navigation-page';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {
  private Router: Router = inject(Router);
  private store: Store = inject(Store);
  public activePage: string = this.Router.url;
  public pages$: Observable<NavigationPage[]> = this.store.select(NavigationSelectors.slices.pages);

  constructor() { }

  ngOnInit(): void {
  }

}
