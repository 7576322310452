import { FetchQuestion } from './../state/questions/questions.state.actions';
import { Injectable } from "@angular/core";
import { Apis } from "src/app/auth/interfaces/apis";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngxs/store";
import { AuthSelectors } from "src/app/auth/state/auth/auth.state.selectors";
import { Observable, map } from "rxjs";
import { Question } from "src/app/assessments/interfaces/payloads/questions";
import Paginated from 'src/app/shared/interfaces/paginated';
import { QuestionStateModel } from '../state/questions/questions.state.model';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';



@Injectable({
  providedIn: "root",
})


export class QuestionsService {
  private apis: Apis = environment.apis;


 constructor(
  private http: HttpClient,
  private store: Store

 ) {}

 public fetchQuestions(paginationPayload: PaginationPayload | undefined = undefined, filter: string | undefined = undefined): Observable<Paginated<QuestionStateModel>> {
  let params = paginationPayload ? Object.entries(paginationPayload).filter(([_, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) : {};

  if (filter) {
    params = { filter, ...params };
  }

  return this.http.get<Paginated<QuestionStateModel>>(
    `${this.apis.questionApi.url}/${this.apis.questionApi.resources.questions}`,
    {
      params
    }
  );
 }

 public fetchQuestion(question: number): Observable<{ data: QuestionStateModel }> {
  return this.http.get<{ data: QuestionStateModel }>(
    `${this.apis.questionApi.url}/${this.apis.questionApi.resources.fetchQuestion}`.replace(':question_id', question.toString())
  );
 }

 public deleteQuestion(question: number): Observable<any> {
  return this.http.delete(
    `${this.apis.questionApi.url}/${this.apis.questionApi.resources.deleteQuestion}`.replace(':question_id', question.toString())
  );
 }

  public createQuestion(payload: Question): Observable<any> {
    return this.http.put(
      `${this.apis.questionApi.url}/${this.apis.questionApi.resources.createQuestion}`,
      payload
    );
  }

  public updateQuestion(payload: Question): Observable<any> {
    return this.http.put<{ data: Question; message: string }>(
      `${this.apis.questionApi.url}/${this.apis.questionApi.resources.editQuestion}`,
      payload
    );
  }




}
