import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from './../../interfaces/payloads/login';
import { Register } from './../../interfaces/payloads/register';
import { passwordRecoveryRequest } from '../../interfaces/payloads/password-recovery';
import { Apis } from '../../interfaces/apis';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '../../state/auth/auth.state.selectors';
import { UpdateProfile } from '../../interfaces/payloads/update-profile';
import Paginated from 'src/app/shared/interfaces/paginated';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';
import { UserCourse } from 'src/app/user/interfaces/payloads/user-course';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apis: Apis = environment.apis;

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) { }

  login(payload: Login): Observable<any> {
    return this.httpClient.post(`${this.apis.authApi.url}/${this.apis.authApi.resources.login}`, payload);
  }

  register(payload: Register): Observable<any> {
    return this.httpClient.post(`${this.apis.authApi.url}/${this.apis.authApi.resources.register}`, payload);
  }

  resetPassword(payload: any): Observable<any> {
    return this.httpClient.post(`${this.apis.authApi.url}/${this.apis.authApi.resources.resetPassword}`, payload);
  }

  passwordRecovery(payload: passwordRecoveryRequest): Observable<any> {
    return this.httpClient.post(`${this.apis.authApi.url}/${this.apis.authApi.resources.passwordRecovery}`, payload);
  }

  getGroups(): Observable<any> {
    return this.httpClient.get(
      `${this.apis.authApi.url}/${this.apis.authApi.resources.userGroups}`
    );
  }

  getRoles(): Observable<any> {
    return this.httpClient.get(
      `${this.apis.authApi.url}/${this.apis.authApi.resources.userRoles}`
    );
  }

  getUser(): Observable<any> {
    return this.httpClient.get(
      `${this.apis.authApi.url}/${this.apis.authApi.resources.user}`
    );
  }

  getUserCourses(paginationPayload: PaginationPayload | undefined = undefined, filter: string | undefined = undefined): Observable<Paginated<UserCourse>> {
    let params = paginationPayload ? Object.entries(paginationPayload).filter(([_, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) : {};

    if (filter) {
      params = { filter, ...params}
    }

    return this.httpClient.get<Paginated<UserCourse>>(
      `${this.apis.authApi.url}/${this.apis.authApi.resources.userCourses}`,
      {
        params
      }
    );
  }

  updateProfile(payload: UpdateProfile): Observable<any> {
    return this.httpClient.patch(
      `${this.apis.authApi.url}/${this.apis.authApi.resources.updateProfile}`,
      payload
    );
  }

  updateProfileImage(formFile: File): Observable<any> {
    const formData = new FormData();
    formData.append('formFile', formFile);

    return this.httpClient.patch(
      `${this.apis.authApi.url}/${this.apis.authApi.resources.UpdateProfileImage}`,
      formData,
      {
        headers: {
          ContentType: 'multipart/form-data',
          Accept: 'image/png, image/jpeg, image/jpg'
        }
      }
    );
  }

  public getUserCourse(userCourseId: number): Observable<UserCourse> {
    const url = `${this.apis.authApi.url}/${this.apis.authApi.resources.userCourse}`.replace(':user_course_id', userCourseId.toString());

    return this.httpClient.get<{ data: UserCourse }>(url).pipe(
      map(response => response.data)
    );
  }

  public finishUnit(course_id: number, unit_id: number) {
    const url = `${this.apis.authApi.url}/${this.apis.authApi.resources.finishUnit}`
      .replace(':course_id', course_id.toString())
      .replace(':unit_id', unit_id.toString());

    return this.httpClient.post(
      url,
      undefined
    );
  }
}
