import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Apis } from 'src/app/auth/interfaces/apis';
import { AuthSelectors } from 'src/app/auth/state/auth/auth.state.selectors';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  private apis: Apis = environment.apis;

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) { }

  public getRoles() : Observable<any> {
    return this.httpClient.get(
      `${this.apis.userRoleApi.url}/${this.apis.userRoleApi.resources.roles}`
    );
  }
}
