import PaginationPayload from "src/app/shared/interfaces/payloads/pagination";
export class LoadUnconfirmedUsers {
    static readonly type = '[UserList] Load Unconfirmed Users';
    constructor(public pagination: PaginationPayload | undefined = undefined) { }
  }
export class LoadConfirmedUsers {
    static readonly type = '[UserList] Load Confirmed Users';
    constructor(
      public pagination: PaginationPayload | undefined = undefined,
      public filter: string | undefined = undefined,
    ) { }

  }
