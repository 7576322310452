import { Injectable } from '@angular/core';
import { Apis } from 'src/app/auth/interfaces/apis';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { AuthSelectors } from 'src/app/auth/state/auth/auth.state.selectors';
import { Observable } from 'rxjs';
import { Assessments } from '../../assessments/interfaces/payloads/assessments';
import { AssessmentStateModel } from '../state/assessments.state.model';
import Paginated from 'src/app/shared/interfaces/paginated';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';



@Injectable({
    providedIn: 'root'
  })

export class AssessmentsService {
  private apis: Apis = environment.apis;

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  public fetchAssessments(paginationPayload: PaginationPayload | undefined = undefined, filter: string | undefined = undefined): Observable<Paginated<AssessmentStateModel>> {
    let params = {};

    if (paginationPayload) {
      params = Object.entries(paginationPayload)
        .filter(([_, v]) => v)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    }

    if (filter) {
      params = { filter, ...params };
    }

    const url = `${this.apis.assessmentApi.url}/${this.apis.assessmentApi.resources.assessments}`;

    return this.http.get<Paginated<AssessmentStateModel>>(url, { params });
  }

  public deleteAssessment(assessment: string): Observable<any> {
    return this.http.delete(
      `${this.apis.assessmentApi.url}/${this.apis.assessmentApi.resources.deleteAssessment}`.replace(':assessment', assessment)
    );
  }

  public createAssessment(payload: Assessments): Observable<any> {
    return this.http.put(
      `${this.apis.assessmentApi.url}/${this.apis.assessmentApi.resources.createAssessment}`,
      payload
    );
  }


}
