import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CourseStateModel } from 'src/app/courses/state/course/course.state.model';
import { ClearCourse, FetchCourse } from './course.state.actions';
import { CourseService } from '../../services/course.service';
import { tap } from 'rxjs';

@State<CourseStateModel>({
  name: 'course',
  defaults: {
    id: undefined,
    name: undefined,
    lives: undefined,
    level_id: undefined,
    category_id: undefined,
    category: undefined,
    status: undefined,
    description: undefined,
    image_url: undefined,
    video: undefined,
    units: undefined,
  },
})
@Injectable()
export class CourseState {
  constructor(private courseService: CourseService) {}

  @Action(FetchCourse)
  public getCourses(ctx: StateContext<CourseStateModel>, action: FetchCourse) {
    return this.courseService.getCourseById(action.id).pipe(
      tap((response: { data: CourseStateModel }) => {
        // sort units by order
        response.data.units = response.data.units?.sort((a, b) => {
          return <number>a.order - <number>b.order;
        });
        ctx.patchState(response.data);
      })
    );
  }



  @Action(ClearCourse)
  public clearCourse(ctx: StateContext<CourseStateModel>) {
    ctx.setState({
      id: undefined,
      name: undefined,
      lives: undefined,
      level_id: undefined,
      category_id: undefined,
      category: undefined,
      status: undefined,
      description: undefined,
      image_url: undefined,
      video: undefined,
      units: undefined,
    });
  }
}
