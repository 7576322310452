import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'main',
    loadChildren: () => import('./sidemenu-layout/main/main.module').then( m => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./shared/pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./shared/pages/home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./user/pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-role',
    loadChildren: () => import('./user/pages/user-role/user-role.module').then( m => m.UserRolePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-group',
    loadChildren: () => import('./user/pages/user-group/user-group.module').then( m => m.UserGroupPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-user',
    loadChildren: () => import('./user/pages/edit-user/edit-user.module').then( m => m.EditUserPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'course-admin',
    loadChildren: () => import('./courses/pages/course-admin/course-admin.module').then( m => m.CourseAdminPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'courses-by-level',
    loadChildren: () => import('./courses/pages/courses-by-level/courses-by-level.module').then( m => m.CoursesByLevelPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-questions',
    loadChildren: () => import('./assessments/pages/dashboard-questions/dashboard-questions.module').then( m => m.DashboardQuestionsPageModule),
    canActivate: [AuthGuard]
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule {}
