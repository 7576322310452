import { BadgesService } from './../../services/badges.service';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FetchBadges, DeleteBadge, CreateBadge } from './badges.state.actions';
import { tap } from 'rxjs';
import { BadgesStateModel } from './badges.state.model';

@State<BadgesStateModel>({
  name: 'badges',
  defaults: {
    data: [],
    page: 1,
    take: 12,
    total: undefined,
    next_page: undefined
  },
})
@Injectable()
export class BadgesState {
  constructor(private BadgesService: BadgesService, private store: Store) {}

  @Action(FetchBadges)
  fetchBadges(ctx: StateContext<BadgesStateModel>, action: FetchBadges) {
    return this.BadgesService.fetchBadges(action.paginationPayload, action.filter).pipe(
      tap((response: BadgesStateModel): any => {
        ctx.patchState(response);
      })
    );
  }

  @Action(DeleteBadge)
  deleteBadge(ctx: StateContext<BadgesStateModel>, action: DeleteBadge) {
    return this.BadgesService.deleteBadge(action.badgeId).pipe(
      tap(() => {
        this.store.dispatch(new FetchBadges());
      })
    );
  }

  @Action(CreateBadge)
  createBadge(ctx: StateContext<BadgesStateModel>, action: CreateBadge) {
    return this.BadgesService.createBadge(action.payload).pipe(
      tap(() => {
        this.store.dispatch(new FetchBadges());
      })
    );
  }
}
