import { AuthService } from './../../services/auth/auth.service';
import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { AuthStateModel } from "./auth.state.model";
import { Login, Logout, Register, PasswordRecovery, ResetPassword, GetGroups, GetRoles, GetUser, UpdateProfileImage, GetUserCourses, LoadCurrentCourse, LoadUnit } from "./auth.state.actions";
import { tap } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserStateModel } from 'src/app/user/state/user/user.state.model';
import Paginated from 'src/app/shared/interfaces/paginated';
import { UserCourse } from 'src/app/user/interfaces/payloads/user-course';
import { UserCourseUnit } from 'src/app/user/interfaces/payloads/user-course-unit';
import { StateReset } from 'ngxs-reset-plugin';
import { states } from 'src/app/app.module';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    id_token: undefined,
    groups: undefined,
    roles: undefined,
    user: {
      image_url: undefined,
      groups: undefined,
      username: undefined,
      last_name: undefined,
      name: undefined,
      roles: undefined,
      email: undefined,
      phone_number: undefined,
      status: undefined,
      courses: undefined,
      currentCourse: undefined
    }
  }
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private alertController: AlertController,
    private router: Router,
    private store: Store
  ) { }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login(action.payload).pipe(
      tap((response: { data: AuthStateModel }) => {
        ctx.patchState(response.data);
      })
    );
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<AuthStateModel>, action: ResetPassword) {
    return this.authService.resetPassword(action.payload).pipe(
      tap({
        error: (error) => {
          throw error;
        },
      })
    );
  }

  @Action(Register)
  register(ctx: StateContext<AuthStateModel>, action: Register) {
    return this.authService.register(action.payload).pipe(
      tap((response: any) => {
        ctx.patchState({id_token: response.token});
      })
    );
  }



  @Action(PasswordRecovery)
  passwordRecovery(ctx: StateContext<AuthStateModel>, action: PasswordRecovery) {
    return this.authService.passwordRecovery(action.payload).pipe(
      tap({
        next: async (response) => {
          const alert = await this.alertController.create({
            header: '¡Éxito!',
            message: response.message,
            buttons: ['OK']
          });
          alert.present();
        }
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, action: Logout) {
    states.forEach(state => {
      this.store.dispatch(new StateReset(state));
    })

    this.router.navigate(['/']);
  }

  @Action(GetGroups)
  getGroups(ctx: StateContext<AuthStateModel>) {
    return this.authService.getGroups().pipe(
      tap({
        next: (response: { data: string[] }) => {
          ctx.patchState({ groups: response.data});
        },
        error: (error: any) => {
          throw error;
        }
      })
    );
  }

  @Action(GetRoles)
  getRoles(ctx: StateContext<AuthStateModel>) {
    return this.authService.getRoles().pipe(
      tap({
        next: (response: { data: string[] }) => {
          ctx.patchState({ roles: response.data});
        },
        error: (error: any) => {
          throw error;
        }
      })
    );
  }

  @Action(UpdateProfileImage)
  updateProfileImage(ctx: StateContext<AuthStateModel>, action: UpdateProfileImage) {
    return this.authService.updateProfileImage(action.payload);
  }

  @Action(GetUser)
  getUser(ctx: StateContext<AuthStateModel>) {
    return this.authService.getUser().pipe(
      tap({
        next: (response: { data: UserStateModel }) => {
          ctx.patchState({ user: response.data });
        },
        error: (error: any) => {
          throw error;
        }
      })
    );
  }

  @Action(GetUserCourses)
  getUserCourses(ctx: StateContext<AuthStateModel>, action: GetUserCourses) {
    return this.authService.getUserCourses(action.pagination, action.filter).pipe(
      tap({
        next: (response: Paginated<UserCourse>) => {
          const user = ctx.getState().user;
          user.courses = response;
          ctx.patchState({ user: user });
        },
        error: (error: any) => {
          throw error;
        }
      })
    );
  }

  @Action(LoadCurrentCourse)
  loadCurrentCourse(ctx: StateContext<AuthStateModel>, action: LoadCurrentCourse) {
    const state = ctx.getState();
    const user = state.user;

    return this.authService.getUserCourse(action.courseId).pipe(
      tap((response: UserCourse) => {
        user.currentCourse = response;

        ctx.patchState({ user });
      })
    );
  }

  @Action(LoadUnit)
  loadUnit(ctx: StateContext<AuthStateModel>, action: LoadUnit) {
    const state = ctx.getState();
    const user = state.user;
    const currentCourse = user.currentCourse as UserCourse;
    const next_unit = currentCourse?.user_course_units
      .find(user_course_unit => user_course_unit.unit.id === action.unitId) as UserCourseUnit;

    currentCourse.current_unit = next_unit.unit;
    user.currentCourse = currentCourse;

    ctx.patchState({ user });
  }
}
