<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-item-group>
      <ion-item color="none">
        <ion-buttons>
          <ion-menu-button slot="start"></ion-menu-button>
        </ion-buttons>
      </ion-item>
      <ion-item color="none" slot="start">
        <ion-img src="/assets/logo_horizontal.png" alt="logo_horizontal" id="logo-horizontal" slot="start" [routerLink]="['/home']"></ion-img>
      </ion-item>
    </ion-item-group>
    <app-avatar-menu slot="end" *ngIf="(authGuard.canActivate() | async) === true as isAuthenticated"></app-avatar-menu>
  </ion-toolbar>
</ion-header>
