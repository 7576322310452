import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { GetRoles } from "./user-role.state.actions";
import { tap } from "rxjs";
import UserRolestateModel from "./user-role.state.model";
import { UserRoleService } from "../../services/user-role/user-role.service";
import UserRoleStateModel from "./user-role.state.model";

@State<UserRolestateModel>({
  name: 'userRole',
  defaults: {
    roles: undefined
  }
})
@Injectable()
export class UserRolestate {

  constructor(
    private userRoleService: UserRoleService
  ) {}

  @Action(GetRoles)
  getGroups(ctx: StateContext<UserRoleStateModel>) {
    return this.userRoleService.getRoles().pipe(
      tap((response: { data: string[] }) => {
        ctx.patchState({ roles: response.data });
      })
    )
  };
}
