import Group from "src/app/shared/interfaces/payloads/group";
import PaginationPayload from "src/app/shared/interfaces/payloads/pagination";

export class GetGroups {
  static readonly type = '[UserGroup] GetGroups';
  constructor(public paginationPayload: PaginationPayload | undefined = undefined, public filter: string | undefined = undefined) { }
}

export class DeleteGroup {
  static readonly type = '[Groups] Delete Group';
  constructor(public group: string) {}
}

export class CreateGroup {
  static readonly type = '[Groups] Create Group';
  constructor(public payload: Group) {}
}
