import { Injectable } from "@angular/core";
import { Action, State } from "@ngxs/store";
import { AcceptUsersService } from "src/app/user/services/user/acceptUsers.service";
import { ResetNavigation } from "./navigation.state.actions";
import { Role } from "../../interfaces/navigation-page";

@State({
  name: 'navigation',
  defaults: {
    pages: [
      {
        title: 'Home',
        url: '/home',
        icon: 'home'
      },
      {
        title: 'Cursos',
        url:  '/main/courses/level/1',
        icon: 'book',
        roles: [Role.STUDENT]
      },
      {
        title: 'Instructores',
        url: '/main/course-admin',
        icon: 'people',
        roles: [Role.INSTRUCTOR]
      },
      {
        title: 'Usuarios',
        url: '/main/users',
        icon: 'people',
        roles: [Role.ADMIN]
      },
    {
      title: 'Evaluaciones',
      url: '/main/dashboard-assessments',
      icon: 'clipboard',
      roles: [Role.ADMIN]
    },
      {
        title: 'Configuración',
        url: '/main/configuration',
        icon: 'settings',
        roles: [Role.ADMIN]
      }
    ]
  }
})
@Injectable()
export class NavigationState {

  @Action(ResetNavigation)
  resetNavigation(ctx: any, action: ResetNavigation) {
    ctx.patchState({
      pages: [
        {
          title: 'Home',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Cursos',
          url:  '/main/courses/level/1',
          icon: 'book'
        },
        {
          title: 'Instructores',
          url: '/main/course-admin',
          icon: 'school'
        },
        {
          title: 'Usuarios',
          url: '/main/users',
          icon: 'people'
        },
        {
          title: 'Evaluaciones',
          url: '/main/dashboard-assessments',
          icon: 'clipboard'
        }
      ]
    });
  }
}
