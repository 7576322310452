import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserStateModel } from '../../state/user/user.state.model';
import { environment } from 'src/environments/environment';
import { Apis } from 'src/app/auth/interfaces/apis';
import { Store } from '@ngxs/store';
import Paginated from 'src/app/shared/interfaces/paginated';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';

@Injectable({
  providedIn: 'root'
})
export class UnconfirmedUsersService {
  private api: Apis = environment.apis

  constructor(
    private http: HttpClient,
    private store: Store
  ) {}

  getUnconfirmedUsers(paginationPayload: PaginationPayload | undefined = undefined): Observable<Paginated<UserStateModel>> {
    let paginationParams = paginationPayload ? Object.entries(paginationPayload).filter(([_, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) : {};

    const url = `${this.api.userApi.url}/${this.api.userApi.resources.list}`;

    return this.http.get<Paginated<UserStateModel>>(
      url,
      {
        params: {
          filter: 'Status::eq::UNCONFIRMED',
          ...paginationParams
        }
      }
    );
  }
}
