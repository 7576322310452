<ion-accordion-group>
  <ion-accordion toggleIcon="none">
    <ion-item color="primary" slot="header">
      <ion-avatar class="ion-margin">
        <ion-img src="{{ (userImage$ | async ) || 'https://ionicframework.com/docs/img/demos/avatar.svg' }}"></ion-img>
      </ion-avatar>
    </ion-item>
    <ion-button slot="content" color="tertiary" (click)="logout()" expand="full">Cerrar sesión</ion-button>
    <ion-button slot="content" *ngFor="let page of pages" style="display: false" [routerLink]="[page.url]" routerDirection="root" [class.active]="" style="width: 150px; text-align: left;" expand="full">
      <ion-label color="{{ page.url === activePage ? 'secondary' : '' }}">
        <ion-icon slot="start" [name]="page.icon" labelPlacement="end" ></ion-icon>
        {{ page.title }}
      </ion-label>
    </ion-button>
  </ion-accordion>
</ion-accordion-group>
