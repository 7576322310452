import { QuestionsPage } from './../../pages/questions/questions.page';
import { Question } from 'src/app/assessments/interfaces/payloads/questions';
import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { QuestionsService } from 'src/app/assessments/services/questions.service';
import { CreateQuestion, DeleteQuestion, FetchQuestions, FetchQuestion } from './questions.state.actions';
import { QuestionStateModel, QuestionsStateModel } from './questions.state.model';
import Paginated from 'src/app/shared/interfaces/paginated';


@State<QuestionsStateModel>({
  name: 'questions',
  defaults: {
    questions: undefined,
    question: undefined

  }
})

@Injectable()
export class QuestionsState {
  constructor(
    private questionsService: QuestionsService,
    private store: Store
  ) {}

  @Action(FetchQuestions)
  fetchQuestions(ctx: StateContext<QuestionsStateModel>, action: FetchQuestions) {
    return this.questionsService.fetchQuestions(action.paginationPayload, action.filter).pipe(
      tap((response: Paginated<QuestionStateModel>): any => {
        ctx.patchState({
          questions: response
        });
      })
    );
  }

  @Action(FetchQuestion)
  fetchQuestion(ctx: StateContext<QuestionsStateModel>, action: FetchQuestion) {
    return this.questionsService.fetchQuestion(action.questionId).pipe(
      tap((response: { data: QuestionStateModel }): any => {
        ctx.patchState({
          question: response.data
        });
      })
    );
  }

  @Action(DeleteQuestion)
  deleteQuestion(
    ctx: StateContext<QuestionsStateModel>,
    action: DeleteQuestion
  ) {
    return this.questionsService.deleteQuestion(action.questionId).pipe(
      tap(() => {
        this.store.dispatch(new FetchQuestions(

        ));
      })
    );
  }

  @Action(CreateQuestion)
  createQuestion(ctx: StateContext<QuestionsStateModel>, action: CreateQuestion) {
    return this.questionsService.createQuestion(action.payload).pipe(
      tap(() => {
        this.store.dispatch(new FetchQuestions(

        ));
      })
    );
  }
}
