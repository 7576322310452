import { Action,State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CourseUnitStateModel } from './course-unit.state.model';
import { tap } from 'rxjs/operators';
import { LoadUnit,SwapUnitOrder } from './course-unit.state.actions';
import { UnitsService } from './../../services/units.service';



@State<CourseUnitStateModel>({
  name: 'unit',
  defaults: {
    id:undefined,
    name:undefined,
    order:undefined,
    description:undefined,
    course_id:undefined,
    lives:undefined,
    video:undefined,
    unit_files:undefined,
    course:undefined
  },
})

@Injectable()
export class CourseUnitState {
  constructor(
    private UnitsService: UnitsService,
    private store: Store
    ) {}

  @Action(LoadUnit)
  loadUnits(ctx: StateContext<CourseUnitStateModel>, action: LoadUnit) {
    return this.UnitsService.getUnit(action.unitId).pipe(
      tap((response: { data: CourseUnitStateModel }): any => {
        ctx.setState(response.data);
    }
    ));
  }

  @Action(SwapUnitOrder)
  swapUnitOrder(ctx: StateContext<CourseUnitStateModel>, action: SwapUnitOrder) {
    return this.UnitsService.swapUnitOrder(action.unitId, action.swapUnitId);
  }
}
