import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseStateModel } from '../state/course/course.state.model';
import { Apis } from 'src/app/auth/interfaces/apis';
import { environment } from 'src/environments/environment';
import { CreateCourse } from '../interfaces/payloads/create-course';
import { EditCourse } from '../interfaces/payloads/edit-course';
import { Store } from '@ngxs/store';
import { AuthSelectors } from 'src/app/auth/state/auth/auth.state.selectors';
import { UpdateVideo } from '../interfaces/payloads/update-video';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';
import { CourseListStateModel } from '../state/course-list/course-list.state.model';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  private apis: Apis = environment.apis;

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  public createCourse(payload: CreateCourse) : Observable<{ data: CourseStateModel; message: string }> {
    return this.http.put<{ data: CourseStateModel; message: string }>(
      `${this.apis.courseApi.url}/${this.apis.courseApi.resources.createCourse}`,
      payload
    );
  }

  public updateCourse(payload: EditCourse) : Observable<{ data: CourseStateModel; message: string }> {
    return this.http.put<{ data: CourseStateModel; message: string }>(
      `${this.apis.courseApi.url}/${this.apis.courseApi.resources.editCourse}`,
      payload
    );
  }

  public getCourses(filter: string|undefined = undefined, paginationPayload: PaginationPayload | undefined = undefined): Observable<CourseListStateModel> {
    let paginationParams = paginationPayload ? Object.entries(paginationPayload).filter(([_, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) : {};

    const url = `${this.apis.courseApi.url}/${this.apis.courseApi.resources.courses}`;

    if (filter) {
      return this.http.get<CourseListStateModel>(
        url,
        {
          params: {
            filter: filter,
            ...paginationParams
          }
        }
      );
    }

    return this.http.get<CourseListStateModel>(url);
  }

  public getCourseById(courseId: number): Observable<{ data: CourseStateModel }> {
    const url = `${this.apis.courseApi.url}/${this.apis.courseApi.resources.fetchCourse}`.replace(':id', courseId.toString());

    return this.http.get<{ data: CourseStateModel }>(
      url
    );
  }

  public updateCourseImage(courseId: number, image: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);

    const url: string = `${this.apis.courseApi.url}/${this.apis.courseApi.resources.updateImage}`.replace(':id', courseId.toString());

    return this.http.put(
      url,
      formData
    );
  }

  public updateCourseVideo(courseId: number, payload: UpdateVideo) : Observable<any> {
    const url: string = `${this.apis.courseApi.url}/${this.apis.courseApi.resources.updateVideo}`.replace(':id', courseId.toString());

    return this.http.put(
      url,
      payload
    );
  }

  public getCompleteCourseById(courseId: number): Observable<{ data: CourseStateModel }> {
    const url = `${this.apis.courseApi.url}/${this.apis.courseApi.resources.fetchCourse}`.replace(':id', courseId.toString());

    return this.http.get<{ data: CourseStateModel }>(
      url
    );
  }

  public enrollToCourse(courseId: number): Observable<any> {
    const url = `${this.apis.courseApi.url}/${this.apis.courseApi.resources.enroll}`.replace(':id', courseId.toString());
    return this.http.post<(CourseStateModel)>(url, {});
  }

  public publish(courseId: number): Observable<{ data: CourseStateModel; message: string }> {
    const url = `${this.apis.courseApi.url}/${this.apis.courseApi.resources.publish}`.replace(':id', courseId.toString());

    return this.http.post<{ data: CourseStateModel; message: string }>(
      url,
      undefined
    );
  }

  deleteCourse(id: number) : Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(
      `${this.apis.courseApi.url}/${this.apis.courseApi.resources.deleteCourse}`.replace(':id', id.toString())
    );
  }
}
