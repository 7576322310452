import { Action, State, StateContext } from '@ngxs/store';
import { UserService } from '../../services/user/user.service';
import { UserStateModel } from "./user.state.model";
import { Injectable } from '@angular/core';
import { GetGroups, GetRoles } from './user.state.actions';
import { AuthStateModel } from 'src/app/auth/state/auth/auth.state.model';
import { tap } from 'rxjs';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    image_url: undefined,
    groups: undefined,
    roles: undefined,
    username: undefined,
    last_name: undefined,
    email: undefined,
    name: undefined,
    phone_number: undefined,
    status: undefined,
    courses: undefined,
    currentCourse: undefined
  }
})



@Injectable()
export class UserState {
  constructor(private userService: UserService) { }

  @Action(GetGroups)
  getGroups(ctx: StateContext<AuthStateModel>, action: GetGroups) {
    return this.userService.getGroups(action.username).pipe(
      tap({
        next: (response: { data: string[] }) => {
          ctx.patchState({ groups: response.data});
        },
        error: (error: any) => {
          throw error;
        }
      })
    );
  }

  @Action(GetRoles)
  getRoles(ctx: StateContext<AuthStateModel>, action: GetRoles) {
    return this.userService.getRoles(action.username).pipe(
      tap({
        next: (response: { data: string[] }) => {
          ctx.patchState({ roles: response.data});
        },
        error: (error: any) => {
          throw error;
        }
      })
    );
  }



}
