import { SideMenuItem } from "./sidemenu.state.model";

export class SetCurrentMenuItem {
    static readonly type = '[SideMenu] SetCurrentMenuItems';
    constructor(public accessor: string[]) { }
}

export class SetSideMenuItems {
    static readonly type = '[SideMenu] SetSideMenuItems';
    constructor(public sideMenuItems: SideMenuItem[]) { }
}

export class ResetSideMenuItems {
    static readonly type = '[SideMenu] ResetSideMenuItems';
    constructor() { }
}
