import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';
import { Assessments } from '../../assessments/interfaces/payloads/assessments';

export class FetchAssessments {
   static readonly type = '[Assessments] Fetch assessments';
    constructor(public paginationPayload: PaginationPayload | undefined = undefined, public filter: string | undefined = undefined) { }
}

export class DeleteAssessment {
 static readonly type = '[Assessments] Delete assessment';
  constructor(public assessmentId: number) { }
}

export class CreateAssessment {
  static readonly type = '[Assessments] Create assessment';
  constructor(public payload: Assessments) {}
}
