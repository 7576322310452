import { Injectable } from '@angular/core';
import { Action, State, StateContext } from "@ngxs/store";
import { SideMenuItem, SideMenuStateModel } from "./sidemenu.state.model";
import { ResetSideMenuItems, SetCurrentMenuItem, SetSideMenuItems } from "./sidemenu.state.actions";
import { LevelTextMap } from 'src/app/courses/maps/level-text-map';

const initialState: SideMenuStateModel = {
  sideMenuItems: [
    <SideMenuItem>{
      name: 'my-courses',
      children: [
        {
          name: 'dashboard',
          title: 'Dashboard',
          url: undefined,
          icon: 'book',
          active: false,
          children: [
            ...LevelTextMap.map((value: { levelText: string, level_id: number }) => {
              return {
                name: `level-${value.level_id}`,
                title: value.levelText,
                url: `/main/courses/level/${value.level_id}`,
                icon: 'book',
                active: false,
                children: undefined
              }
            }),
            {
              name: 'my-courses',
              title: 'Mis Cursos',
              url: '/main/dashboard',
              icon: 'book',
              active: false,
              children: undefined
            },
          ]
        }
      ],
    },
    <SideMenuItem>{
      name: 'users',
      children: [
        {
          name: 'users',
          title: 'Usuarios',
          url: undefined,
          icon: 'people',
          active: false,
          children: [
            {
              name: 'users',
              title: 'Usuarios Confirmados',
              url: '/main/users',
              icon: 'people',
              active: false,
              children: undefined
            },
            {
              name: 'unconfirmedusers',
              title: 'Usuarios No Confirmados',
              url: '/main/Unconfirmedusers',
              icon: 'people',
              active: false,
              children: undefined
            },
            {
              name: 'register-user',
              title: 'Registrar Usuario',
              url: '/main/register-user',
              icon: 'person-add',
              active: false,
              children: undefined
            },
            {
              name: 'groups',
              title: 'Grupos',
              url: '/main/groups',
              icon: 'people',
              active: false,
              children: undefined
            },
            {
              name: 'categories',
              title: 'Categorias',
              url: '/main/categories',
              icon: 'book',
              active: false,
              children: undefined
            },
            {
              name: 'user-assessments-report',
              title: 'Reporte de evaluaciones',
              url: '/main/user-assessments-report',
              icon: 'clipboard',
              active: false,
              children: undefined
            },
          ]
        }
      ]
    },
    <SideMenuItem>{
      name: 'instructors',
      children: [
        {
          name: 'instructors',
          title: 'Instructores',
          url: undefined,
          icon: 'people',
          active: false,
          children: [
            {
              name: 'course-admin',
              title: 'Administrar Cursos',
              url: '/main/course-admin',
              icon: 'book',
              active: false,
              children: undefined
            }
          ]
        }
      ]
    },
    <SideMenuItem>{
      name: 'assessments',
      children: [
        {
          name: 'assessments',
          title: 'Evaluaciones',
          url: undefined,
          icon: 'clipboard',
          active: false,
          children: [
            {
              name: 'assessments',
              title: 'Administrar de Evaluaciones',
              url: '/main/dashboard-assessments',
              icon: 'clipboard',
              active: false,
              children: undefined
            },
            {
              name: 'dashboard-questions',
              title: 'Administrar Preguntas',
              url: '/main/dashboard-questions',
              icon: 'help',
              active: false,
              children: undefined
            },
            {
              name: 'questions',
              title: 'Crear preguntas',
              url: '/main/questions',
              icon: 'help-circle',
              active: false,
              children: undefined
            },
            {
              name: 'badges',
              title: 'Crear Insignias',
              url: '/main/badges',
              icon: 'ribbon',
              active: false,
              children: undefined
            }
          ]
        }
      ]
    },
    <SideMenuItem>{
      name: 'configuration',
      children: [
        {
          name: 'configuration',
          title: 'Configuración',
          url: undefined,
          icon: 'settings',
          active: false,
          children: [
            {
              name: 'configuration',
              title: 'Configuración',
              url: '/main/configuration',
              icon: 'settings',
              active: false,
              children: undefined
            }
          ]
        }
      ]
    },
  ],
  currentMenuItem: undefined
};

@State<SideMenuStateModel>({
  name: 'sideMenu',
  defaults: initialState
})
@Injectable()
export default class SideMenuState {
  constructor() { }

  @Action(SetCurrentMenuItem)
  setCurrentMenuItem(ctx: StateContext<SideMenuStateModel>, action: SetCurrentMenuItem) {
    const state = ctx.getState();

    // recursively find the current menu item from the string array accessor
    const currentMenuItem: SideMenuItem[] | undefined = this.findMenuItem(state.sideMenuItems, action.accessor);

    ctx.patchState({
      currentMenuItem
    });
  }

  @Action(SetSideMenuItems)
  setSideMenuItems(ctx: StateContext<SideMenuStateModel>, action: SetSideMenuItems) {
    ctx.patchState({
      currentMenuItem: action.sideMenuItems
    });
  }

  private findMenuItem(menuItems: SideMenuItem[] | undefined, accessor: string[]): SideMenuItem[] | undefined {
    // base case
    if (accessor.length === 0) {
      return menuItems;
    }

    // recursive case
    const currentAccessor = accessor[0];
    const nextAccessor = accessor.slice(1);

    const currentMenuItem: SideMenuItem | undefined = menuItems?.find((item: SideMenuItem | undefined) => item?.name === currentAccessor) ?? undefined;

    if (currentMenuItem?.children !== undefined && currentMenuItem?.children?.length > 0 && nextAccessor.length > 0) {
      return this.findMenuItem(currentMenuItem.children, nextAccessor);
    }

    return currentMenuItem?.children ?? undefined;
  }

  @Action(ResetSideMenuItems)
  resetSideMenuItems(ctx: StateContext<SideMenuStateModel>, action: ResetSideMenuItems) {
    ctx.patchState({
      sideMenuItems: initialState.sideMenuItems
    });
  }
}
