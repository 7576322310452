import { AcceptUsersService } from './../../services/user/acceptUsers.service';
import { UnconfirmedUsersService } from './../../services/user/unconfirmedUsers.service';
import { Injectable } from '@angular/core';
import { UserListStateModel } from './user-list.state.model';
import { Action, State, StateContext } from '@ngxs/store';
import { LoadUnconfirmedUsers } from './user-list.state.actions';
import { tap } from 'rxjs/operators';
import { UserStateModel } from '../user/user.state.model';
import { LoadConfirmedUsers } from './user-list.state.actions';
import Paginated from 'src/app/shared/interfaces/paginated';

@State<UserListStateModel>({
  name: 'user_list',
  defaults: {
    unconfirmed_users: undefined,
    confirmed_users: undefined
  }
})
@Injectable()
export class UserListState {
  constructor(
    private unconfirmedUsersService: UnconfirmedUsersService,
    private acceptUsersService: AcceptUsersService
  ) {}

  @Action(LoadUnconfirmedUsers)
  loadUnconfirmedUsers(ctx: StateContext<UserListStateModel>, action: LoadUnconfirmedUsers) {
    return this.unconfirmedUsersService.getUnconfirmedUsers(action.pagination).pipe(
      tap((response: Paginated<UserStateModel>) => {
        ctx.patchState({
          unconfirmed_users: response
        });
      })
    );
  }

  @Action(LoadConfirmedUsers)
  loadConfirmedUsers(ctx: StateContext<UserListStateModel>, action: LoadConfirmedUsers) {
    return this.acceptUsersService.getConfirmedUsers(action.pagination, action.filter).pipe(
      tap((response: Paginated<UserStateModel>) => {
        ctx.patchState({
          confirmed_users: response
        });
      })
    );
  }
}
