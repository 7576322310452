import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { CreateGroup, DeleteGroup, GetGroups } from "./user-group.state.actions";
import { UserGroupService } from "../../services/user-group/user-group.service";
import { tap } from "rxjs";
import UserGroupStateModel from "./user-group.state.model";

@State<UserGroupStateModel>({
  name: 'userGroup',
  defaults: {
    groups: undefined
  }
})
@Injectable()
export class UserGroupState {

  constructor(
    private userGroupService: UserGroupService,
    private store: Store
  ) { }

  @Action(GetGroups)
  getGroups(ctx: StateContext<UserGroupStateModel>, action: GetGroups) {
    return this.userGroupService.getGroups(action.paginationPayload, action.filter).pipe(
      tap((response: { data: string[] }) => {
        ctx.patchState({ groups: response.data });
      })
    )
  };

  @Action(DeleteGroup)
  deleteGroup(ctx: any, action: DeleteGroup) {
    return this.userGroupService.deleteGroup(action.group).pipe(
      tap((response) => {
        this.store.dispatch(new GetGroups());
      }
    ));
  }

  @Action(CreateGroup)
  createGroup(ctx: any, action: CreateGroup) {
    return this.userGroupService.createGroup(action.payload).pipe(
      tap((response) => {
        this.store.dispatch(new GetGroups());
      }
    ));
  }
}
