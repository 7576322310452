import { Store } from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthSelectors } from '../state/auth/auth.state.selectors';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private state: Store = inject(Store);

  canActivate(): Observable<boolean> | Observable<boolean | UrlTree> {
    return this.state.select(AuthSelectors.isAuthenticated);
  }



}
