import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { Store } from '@ngxs/store';
import { Logout } from '../auth/state/auth/auth.state.actions';
import { AuthSelectors } from '../auth/state/auth/auth.state.selectors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.store.selectSnapshot(AuthSelectors.idToken);

    if (token && !request.headers.has('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 0)) {
          this.store.dispatch(new Logout());
          throw error;
        }

        throw error;
      }));
  }
}
