import { passwordRecoveryRequest } from './../../interfaces/payloads/password-recovery';
import { Login as LoginPayload } from '../../interfaces/payloads/login';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';

export class Login {
  static readonly type = '[Auth Login Component] Login';
  constructor(public payload: LoginPayload) {}
}
export class Register {
  static readonly type = '[Auth Register Component] Register';
  constructor(public payload: any) {}
}


export class ResetPassword {
  static readonly type = '[Auth Login Component] resetPassword';
  constructor(public payload: any) {}
}

export class PasswordRecovery {
  static readonly type = '[Auth Login Component] PasswordRecovery';
  constructor(public payload: passwordRecoveryRequest) {}
}

export class Logout {
  static readonly type = '[Auth Avatar Menu Component] Logout';
  constructor() {}
}

export class GetGroups {
  static readonly type = '[Auth Login Component] GetGroups';
  constructor() {}
}

export class GetRoles {
  static readonly type = '[Auth Login Component] GetRoles';
  constructor() { }
}

export class GetUser {
  static readonly type = '[Auth Login Component] GetUser';
  constructor() { }
}

export class UpdateProfileImage {
  static readonly type = '[Edit Profile Component] UpdateProfileImage';
  constructor(public payload: File) { }
}

export class GetUserCourses {
  static readonly type = '[Auth Login Component] GetUserCourses';
  constructor(public pagination: PaginationPayload | undefined = undefined, public filter: string | undefined = undefined) { }
}

export class LoadCurrentCourse {
  static readonly type = '[Auth User] LoadCurrentCourse';
  constructor(public courseId: number) {}
}

export class LoadUnit {
  static readonly type = '[Auth User] LoadUnit';
  constructor(public unitId: number) {}
}
