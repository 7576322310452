import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { LoadCourses } from './course-list.state.actions';
import { CourseListStateModel } from './course-list.state.model';
import { CourseService } from '../../services/course.service';
import { tap } from 'rxjs/operators';

@State<CourseListStateModel>({
  name: 'course_list',
  defaults: {
    data: [],
    page: 1,
    take: 12,
    total: undefined,
    next_page: undefined
  },
})
@Injectable()
export class CourseListState {
  constructor(private courseService: CourseService) {}

  @Action(LoadCourses)
  loadCourses(ctx: StateContext<CourseListStateModel>, action: LoadCourses) {
    return this.courseService.getCourses(action.filter, action.pagination).pipe(
      tap((response: CourseListStateModel) => {
        const courses = response.data;

        ctx.patchState({
          ...response,
          data: courses
        });
      })
    );
  }
}
