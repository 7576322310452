import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apis } from 'src/app/auth/interfaces/apis';
import { environment } from 'src/environments/environment';
import UserAssessmentsReportStateModel from '../../state/user-assessments-report.state.model';

@Injectable({
  providedIn: 'root'
})
export class UserAssessmentsReportServiceService {
  private apis: Apis = environment.apis;

  constructor(
    private httpClient: HttpClient,
  ) { }

  fetchReport(): Observable<UserAssessmentsReportStateModel> {
    const url = `${this.apis.userAssessmentApi.url}/${this.apis.userAssessmentApi.resources.report}`;

    return this.httpClient.get<UserAssessmentsReportStateModel>(url);
  }

  downloadReport(): Observable<Blob> {
    const url = `${this.apis.userAssessmentApi.url}/${this.apis.userAssessmentApi.resources.reportCSV}`;
    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
