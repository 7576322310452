import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ResetLoading } from './shared/state.loading/loading.state.actions';
import { ResetNavigation } from './shared/state/navigation/navigation.state.actions';
import { ResetSideMenuItems } from './shared/state/sidebar/sidemenu.state.actions';
import { ProgressBarTask, ProgressbarService } from './shared/services/progressbar/progressbar.service';
import { Observable, Subscription } from 'rxjs';
import { GetRoles, GetUser } from './auth/state/auth/auth.state.actions';
import { NavigationState } from './shared/state/navigation/navigation.state';
import { StateReset } from 'ngxs-reset-plugin';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  protected tasks: ProgressBarTask[] = [];
  private task$: Observable<ProgressBarTask[]> = this.progressBar.tasksObservable;
  private taskSub: Subscription = this.task$.subscribe(tasks => this.tasks = tasks);

  constructor(
    private store: Store,
    private progressBar: ProgressbarService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new ResetLoading);
    this.store.dispatch(new ResetNavigation);
    this.store.dispatch(new ResetSideMenuItems);

    this.store.dispatch([
      new GetRoles(),
      new GetUser()
    ]);

    this.store.dispatch(new StateReset(NavigationState));
  }

  ngOnDestroy() {
    this.taskSub.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHndler($event: BeforeUnloadEvent): void {
    if (this.tasks.length > 0) {
      $event.returnValue = true;
    }
  }
}
