import { CreateCategory } from './../../shared/state/categories/categories.state.actions';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Apis } from "src/app/auth/interfaces/apis";
import { environment } from "src/environments/environment";
import CategoriesStateModel, { CategoryStateModel } from 'src/app/shared/state/categories/categories.state.model';
import { Store } from '@ngxs/store';
import { AuthSelectors } from 'src/app/auth/state/auth/auth.state.selectors';
import {Category} from 'src/app/shared/interfaces/payloads/category';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';

@Injectable({
  providedIn: 'root'
})

export class CategoriesService {
  private apis: Apis = environment.apis;

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  public fetchCategories(paginationPayload: PaginationPayload | undefined = undefined, filter: string | undefined = undefined): Observable<CategoriesStateModel> {
    let params = paginationPayload ? Object.entries(paginationPayload).filter(([_, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) : {};

    if (filter) {
      params = { filter, ...params };
    }

    return this.http.get<CategoriesStateModel>(
      `${this.apis.categoriesApi.url}/${this.apis.categoriesApi.resources.categories}`,
      {
        params
      }
    );
  }

  public deleteCategory(categoryId: number): Observable<{ data: CategoryStateModel }> {
    return this.http.delete<{ data: CategoryStateModel }>(
      `${this.apis.categoriesApi.url}/${this.apis.categoriesApi.resources.deleteCategory}`.replace(':category_id', categoryId.toString())
    );

  }

  public createCategory(payload: Category): Observable<any> {
    return this.http.put(
      `${this.apis.categoriesApi.url}/${this.apis.categoriesApi.resources.createCategory}`,
      payload
    );
  }
}
