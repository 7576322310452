<footer>
  <ion-toolbar color="primary">
    <ion-item slot="start" color="primary">
      <ion-img src="/assets/irontraining_logo.png" alt="irontraining_logo" id="logo"></ion-img>
    </ion-item>
    <ion-item color="primary" *ngIf="task" >
      <ion-text>{{ task.message }}</ion-text>
      <ion-progress-bar [value]="task.progress / task.total" color="secondary"></ion-progress-bar>
    </ion-item>
  </ion-toolbar>
</footer>
