import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { IonicModule } from '@ionic/angular';
import { HeaderRoutingModule } from './header-routing.module';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { NavigationMenuComponent } from '../navigation-menu/navigation-menu.component';
import { AvatarMenuComponent } from '../avatar-menu/avatar-menu.component';



@NgModule({
  declarations: [
    HeaderComponent,
    SearchBarComponent,
    NavigationMenuComponent,
    AvatarMenuComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    HeaderRoutingModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
