import { Observable, Subscription } from 'rxjs';
import { ProgressBarTask, ProgressbarService } from './../../services/progressbar/progressbar.service';
import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent  implements OnDestroy {
  protected task: ProgressBarTask | undefined;
  private task$: Observable<ProgressBarTask[]> = this.progressBar.tasksObservable;
  private taskSub: Subscription = this.task$.subscribe(tasks => this.task = tasks[0]);

  constructor(private progressBar: ProgressbarService) { }

  ngOnDestroy() {
    this.taskSub.unsubscribe();
  }

}
