import { Component, OnInit, inject } from '@angular/core';
import { AuthGuard } from 'src/app/auth/guards/auth.guard';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent  implements OnInit {
  public authGuard: AuthGuard = inject(AuthGuard);

  constructor() { }

  ngOnInit() {}

}
