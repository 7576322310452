import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Apis } from 'src/app/auth/interfaces/apis';
import { AuthSelectors } from 'src/app/auth/state/auth/auth.state.selectors';
import Group from 'src/app/shared/interfaces/payloads/group';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {
  private apis: Apis = environment.apis;

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) { }

  public getGroups(paginationPayload: PaginationPayload | undefined = undefined, filter: string | undefined = undefined) : Observable<any> {
    let params = paginationPayload ? Object.entries(paginationPayload).filter(([_, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) : {};

    if (filter) {
      params = { filter, ...params };
    }

    return this.httpClient.get(
      `${this.apis.userGroupApi.url}/${this.apis.userGroupApi.resources.groups}`,
      { params }
    );
  }

  public deleteGroup(group: string): Observable<any> {
    return this.httpClient.delete(
      `${this.apis.userGroupApi.url}/${this.apis.userGroupApi.resources.deleteGroup}`.replace(':group', group)
    );
  }

  public createGroup(payload: Group): Observable<any> {
    return this.httpClient.put(
      `${this.apis.userGroupApi.url}/${this.apis.userGroupApi.resources.createGroup}`,
      payload
    );
  }
}
