import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ResetLoading } from '../../state.loading/loading.state.actions';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading: HTMLIonLoadingElement|undefined;

  constructor(
    private loadingCtrl: LoadingController,
    private store: Store
  ) { }

  show(timeout: number) {
    this.loadingCtrl.create({
      message: 'Por favor, aguarde...'
    }).then(async loading => {
      this.loading = loading;
      await this.loading?.present();

      if (this.store.selectSnapshot(state => state.loading.clientsCounter) === 0) {
        timeout = 0;
      }

      setTimeout(
        () => {
          loading?.dismiss();
          this.store.dispatch(new ResetLoading());
        },
        timeout
      );
    });

  }

  hide() {
    this.loading?.dismiss();
  }
}
