export interface NavigationPage {
  title: string;
  url: string;
  icon: string;
  roles: Role[] | undefined;
}

export enum Role {
  ADMIN = 'admin',
  STUDENT = 'student',
  INSTRUCTOR = 'instructor'
}
