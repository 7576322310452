import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface ProgressBarTask {
  id: string;
  progress: number;
  total: number;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {
  private tasks: ProgressBarTask[] = [];
  private tasks$: Subject<ProgressBarTask[]> = new Subject();

  constructor() { }

  addTask(message: string, total: number, progress: number = 0): string {
    let id = Math.random().toString(36).substring(2, 9);

    while (this.tasks.find(task => task.id === id)) {
      id = Math.random().toString(36).substring(2, 9);
    }

    this.tasks.push({
      id,
      progress,
      total,
      message
    });

    this.tasks$.next(this.tasks);

    return id;
  }

  removeTask(id: string) {
    this.tasks = this.tasks.filter(task => task.id !== id);
    this.tasks$.next(this.tasks);
  }

  setTaskProgress(id: string, progress: number) {
    const task = this.tasks.find(task => task.id === id);

    if (task) {
      task.progress = progress;

      if (progress >= task.total) {
        this.removeTask(id);
      }
    }

    this.tasks$.next(this.tasks);

    return task;
  }

  get tasksObservable() {
    return this.tasks$.asObservable();
  }
}
