import PaginationPayload from "src/app/shared/interfaces/payloads/pagination";
import { Badge } from "../../interfaces/payloads/badges";

export class FetchBadges {
  static readonly type = '[Badges] Fetch badges';
  constructor(public paginationPayload: PaginationPayload | undefined = undefined, public filter: string | undefined = undefined) {}
}

export class DeleteBadge {
  static readonly type = '[Badges] Delete badge';
  constructor(public badgeId: number) {}
}

export class CreateBadge {
  static readonly type = '[Badges] Create badge';
  constructor(public payload: Badge) {}
}
