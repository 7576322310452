import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription, tap } from 'rxjs';
import { Apis } from 'src/app/auth/interfaces/apis';
import { GetRoles, Logout } from 'src/app/auth/state/auth/auth.state.actions';
import { AuthSelectors } from 'src/app/auth/state/auth/auth.state.selectors';
import { environment } from 'src/environments/environment';
import { NavigationPage } from '../../interfaces/navigation-page';
import { Router } from '@angular/router';
import { NavigationSelectors } from '../../state/navigation/navigation.state.selectors';

@Component({
  selector: 'app-avatar-menu',
  templateUrl: './avatar-menu.component.html',
  styleUrls: ['./avatar-menu.component.scss']
})
export class AvatarMenuComponent implements OnInit {
  public userImage$: Observable<string|ArrayBuffer|undefined|null> = this.store.select(AuthSelectors.userImageUrl);
  public apis: Apis = environment.apis;
  public pages: NavigationPage[] | undefined;
  public roles$: Observable<string[] | undefined> = this.store.select(AuthSelectors.roles);
  public rolesSub: Subscription | undefined;
  public activePage: string = this.router.url;

  public showMenu: boolean = false;
  constructor(
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.rolesSub = this.roles$.subscribe({
      next: roles => {
        this.pages = this.store.selectSnapshot(NavigationSelectors.slices.pages)?.filter(page => {
          return page.roles?.some(role => {
            return roles?.includes(role);
          });
        })
      }
    });
    this.store.dispatch(new GetRoles());
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
