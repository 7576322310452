export class ShowLoading {
  static readonly type = '[Loading] Show';
}

export class HideLoading {
  static readonly type = '[Loading] Hide';
}

export class SetLoadingTimeout {
  static readonly type = '[Loading] Set Timeout';
  constructor(public timeout: number) {}
}

export class ResetLoading {
  static readonly type = '[Loading] Reset';
}
