import { AssessmentStateModel } from './assessments/state/assessments.state.model';
import { CategoriesState } from './shared/state/categories/categories.state';
import { UserState } from './user/state/user/user.state';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthState } from './auth/state/auth/auth.state';
import { NavigationState } from './shared/state/navigation/navigation.state';
import { FormsModule } from '@angular/forms';
import { UserListState } from './user/state/user-list/user-list.state';

import { UserGroupState } from './user/state/user-group/user-group.state';
import { UserRolestate } from './user/state/user-role/user-role.state';
import { LoadingState } from './shared/state.loading/loading.state';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { CourseListState } from './courses/state/course-list/course-list.state';
import { CourseState } from './courses/state/course/course.state';
import { CourseUnitState } from './courses/state/course-unit/course-unit.state';
import SideMenuState from './shared/state/sidebar/sidemenu.state';
import { AssessmentsState } from './assessments/state/assessments.state';
import { BadgesState } from './assessments/state/badges/badges.state';
import { QuestionsState } from './assessments/state/questions/questions.state';
import { CommonModule } from './shared/modules/common/common.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import UserAssessmentsReportState from './user-assessments-report/state/user-assessments-report.state';

export const states = [
  AuthState,
  NavigationState,
  UserState,
  UserListState,
  UserGroupState,
  UserRolestate,
  LoadingState,
  CourseState,
  CourseListState,
  CourseUnitState,
  SideMenuState,
  CategoriesState,
  AssessmentsState,
  BadgesState,
  QuestionsState,
  UserAssessmentsReportState
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxsStoragePluginModule.forRoot(),
    NgxsModule.forRoot(states),
    BrowserModule,
    IonicModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    NgxsResetPluginModule.forRoot(),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'Window', useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
