import { Injectable } from '@angular/core';
import {
  CreateAssessment,
  DeleteAssessment,
  FetchAssessments,
} from './assessments.state.actions';
import { AssessmentsService } from './../services/assessments.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { AssessmentStateModel, AssessmentsStateModel } from './assessments.state.model';
import Paginated from 'src/app/shared/interfaces/paginated';

@State<AssessmentsStateModel>({
  name: 'assessments',
  defaults: {
    data: [],
    page: undefined,
    take: undefined,
    total: undefined,
    next_page: undefined,
  },
})
@Injectable()
export class AssessmentsState {
  constructor(
    private assessmentsService: AssessmentsService,
    private store: Store
  ) {}

  @Action(FetchAssessments)
  fetchAssessments(ctx: StateContext<AssessmentsStateModel>, action: FetchAssessments) {
    return this.assessmentsService.fetchAssessments(action.paginationPayload, action.filter).pipe(
      tap((response: Paginated<AssessmentStateModel>): any => { // Cambia el tipo aquí
        ctx.patchState(response);
      })
    );
  }

  @Action(DeleteAssessment)
  deleteAssessment(
    ctx: StateContext<AssessmentsStateModel>,
    action: DeleteAssessment
  ) {
    return this.assessmentsService.deleteAssessment(action.assessmentId.toString()).pipe(
      tap(() => {
        this.store.dispatch(new FetchAssessments(
        {
          take: 10,
          page: 1
        }
        ));
      })
    );
  }

  @Action(CreateAssessment)
  createAssessment(ctx: StateContext<AssessmentsStateModel>, action: CreateAssessment) {
    return this.assessmentsService.createAssessment(action.payload).pipe(
      tap(() => {
        this.store.dispatch(new FetchAssessments(
          {
            take: 10,
            page: 1
          }
        ));
      })
    );
  }
}
