
import { Injectable } from '@angular/core';
import { Apis } from 'src/app/auth/interfaces/apis';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { AuthSelectors } from 'src/app/auth/state/auth/auth.state.selectors';
import { Observable } from 'rxjs';
import { Badge as BadgePayload } from '../interfaces/payloads/badges';
import { BadgesStateModel } from '../state/badges/badges.state.model';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';

@Injectable({
  providedIn: 'root'
})

export class BadgesService {
  private apis: Apis = environment.apis;

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  public fetchBadges(paginationPayload: PaginationPayload | undefined = undefined, filter: string | undefined = undefined): Observable<BadgesStateModel> {
    let params = paginationPayload ? Object.entries(paginationPayload).filter(([_, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) : {};

    if (filter) {
      params = { filter, ...params };
    }

    return this.http.get<BadgesStateModel>(
      `${this.apis.badgeApi.url}/${this.apis.badgeApi.resources.badges}`,
      {
        params
      }
    );
  }
  public deleteBadge(badge: number): Observable<any> {
    return this.http.delete(
      `${this.apis.badgeApi.url}/${this.apis.badgeApi.resources.deleteBadge}`.replace(':badge_id', badge.toString())
    );
  }
  public createBadge(payload: BadgePayload): Observable<any> {
    const formData = new FormData();
    formData.append('id', payload.id?.toString() ?? '');
    formData.append('image', <Blob>payload.image);
    formData.append('name', payload.name);
    formData.append('is_active', payload.is_active.valueOf().toString());

    return this.http.put(
      `${this.apis.badgeApi.url}/${this.apis.badgeApi.resources.createBadge}`,
      formData,
      {
        headers: {
          ContentType: 'multipart/form-data',
          Accept: 'image/png, image/jpeg, image/jpg'
        }
      }
    );
  }
}
