import {Category} from "../../interfaces/payloads/category";
import PaginationPayload from "../../interfaces/payloads/pagination";

export class FetchCategories {
  static readonly type = '[Categories] Fetch categories';
  constructor(public paginationPayload: PaginationPayload | undefined = undefined, public filter: string | undefined = undefined) { }
}

export class DeleteCategory {
  static readonly type = '[Categories] Delete category';
  constructor(public categoryId: number) { }
}

export class CreateCategory {
  static readonly type = '[Categories] Create Category';
  constructor(public payload: Category) {}
}
