import { UpdateUser } from './../../interfaces/payloads/update-user';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apis } from 'src/app/auth/interfaces/apis';
import { Register } from 'src/app/auth/interfaces/payloads/register';
import { AuthSelectors } from 'src/app/auth/state/auth/auth.state.selectors';
import { environment } from 'src/environments/environment';
import { UserStateModel } from '../../state/user/user.state.model';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apis: Apis = environment.apis;

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {}

  getUsers(): Observable<UserStateModel[]> {
    return this.httpClient.get<UserStateModel[]>(`${this.apis.userApi.url}/${this.apis.userApi.resources.users}`,
    {
    params: {
      filter: 'cognito:user_status = "CONFIRMED"',
      limit: '10',
      sort: 'username',
      sortOrder: 'ASC',
    },
  });
  }

  public register(payload: Register): Observable<any> {
    return this.httpClient.put(
      `${this.apis.userApi.url}/${this.apis.userApi.resources.register}`,
      payload
    );
  }

  public updateUser(username: string|undefined, payload: UpdateUser): Observable<any> {
    const url = `${this.apis.userApi.url}/${this.apis.userApi.resources.updateUser}`.replace(':username', <string>username);
    return this.httpClient.patch(url, payload);
  }

  public getGroups(username: string) : Observable<any> {
    const url = `${this.apis.userApi.url}/${this.apis.userApi.resources.userGroups}`.replace(':username', username);
    return this.httpClient.get(url);
  }

  public getRoles(username: string) : Observable<any> {
    const url = `${this.apis.userApi.url}/${this.apis.userApi.resources.userRoles}`.replace(':username', username);
    return this.httpClient.get(url);
  }

  public deleteUser(username: string) : Observable<any> {
    const url = `${this.apis.userApi.url}/${this.apis.userApi.resources.delete}`.replace(':username', username);
    return this.httpClient.delete(url);
  }
}
