import { CreateAssessment } from './../app/assessments/state/assessments.state.actions';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apis: {
    authApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/Auth',
      resources: {
        login: 'signin',
        userGroups: 'groups',
        userRoles: 'roles',
        register: 'signup',
        resetPassword: 'resetPassword',
        passwordRecovery: 'passwordRecoveryRequest',
        user: 'User',
        updateProfile: 'User',
        UpdateProfileImage: 'User/image',
        userCourses: 'User/myCourses',
        userCourse: 'User/Course/:user_course_id',
        finishUnit: 'User/Course/:course_id/Unit/:unit_id/finish'
      },
    },
    userApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/User',
      resources: {
        users: '',
        list: 'list',
        updateUser: ':username',
        accept: ':username/accept',
        reject: ':username/reject',
        register: '',
        groups: 'groups',
        roles: 'roles',
        userGroups: ':username/groups',
        userRoles: ':username/roles',
        delete: ':username',
      },
    },
    userGroupApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/UserGroup',
      resources: {
        groups: '',
        deleteGroup: ':group',
        createGroup: '',
      },
    },
    userRoleApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/UserRole',
      resources: {
        roles: '',
      },
    },
    courseApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/Course',
      resources: {
        courses: '',
        fetchCourse: ':id',
        createCourse: '',
        editCourse: '',
        updateImage: ':id/image',
        updateVideo: ':id/video',
        enroll: ':id/enroll',
        publish: ':id/publish',
        deleteCourse: ':id'
      },
    },
    categoriesApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/Category',
      resources: {
        categories: '',
        deleteCategory: ':category_id',
        createCategory: '',
      },
    },
    unitApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/Unit',
      resources: {
        units: '',
        fetchUnit: ':unit_id',
        updateVideo: ':id/video',
        editUnit: '',
        uploadFile: ':id/File',
        deleteFile: ':id/File/:file_id',
        deleteUnit: ':id',
        downloadFile: ':id/File/:file_id',
        swapUnit: ':unit_id/swap/:swap_id',
      },
    },
    vimeoApi: {
      url: 'https://api.vimeo.com',
      access_token: 'd6ef4e0936df60e23b056687500e4f2c',
      resources: {
        upload: 'me/videos',
      },
    },
    assessmentApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/Assessment',
      resources: {
        assessments: '',
        deleteAssessment: ':assessment',
        createAssessment: '',
      },
    },
    badgeApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/Badge',
      resources: {
        badges: '',
        deleteBadge: ':badge_id',
        createBadge: ''
      },
    },
    questionApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/Question',
      resources: {
        questions: '',
        deleteQuestion: ':question_id',
        createQuestion: '',
        editQuestion: '',
        fetchQuestion: ':question_id',

      },
    },
    userAssessmentApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/UserAssessment',
      resources: {
        report: 'report',
        reportCSV: 'report/csv'
      }
    },
    backgroundImageApi: {
      url: 'https://6ol8v90zhg.execute-api.us-east-1.amazonaws.com/Dev/BackgroundImage',
      resources: {
        updateDesktop: 'desktop',
        updateMobile: 'mobile',
        getDesktop: 'desktop',
        getMobile: 'mobile'
      }
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
