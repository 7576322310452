import { Injectable } from '@angular/core';
import { CreateCategory, DeleteCategory, FetchCategories } from './categories.state.actions';
import { CategoriesService } from './../../../courses/services/categories.service';
import { Action, State, StateContext, Store } from "@ngxs/store";
import CategoriesStateModel, { CategoryStateModel } from 'src/app/shared/state/categories/categories.state.model';
import { tap } from 'rxjs';
import PaginationPayload from '../../interfaces/payloads/pagination';

@State<CategoriesStateModel>({
  name: 'courseCategory',
  defaults: {
    data: [],
    page: 1,
    take: 12,
    total: undefined,
    next_page: undefined
  }
})
@Injectable()
export class CategoriesState {
  constructor(
    private categoriesService: CategoriesService,
    private store: Store
  ) { }

  @Action(FetchCategories)
  fetchCategories(ctx: StateContext<CategoriesStateModel>, action: FetchCategories) {
    return this.categoriesService.fetchCategories(action.paginationPayload, action.filter).pipe(
        tap((response: CategoriesStateModel): any => {
          ctx.setState(response);
      }));
  }

  @Action(DeleteCategory)
  deleteCategory(ctx: StateContext<CategoriesStateModel>, action: DeleteCategory) {
    const state = ctx.getState();

    return this.categoriesService.deleteCategory(action.categoryId).pipe(
      tap((response: { data: CategoryStateModel }): any => {
        const pagination: PaginationPayload = {
          page: 1,
          take: state.take
        };

        this.store.dispatch(new FetchCategories(pagination));

        ctx.patchState({ page: 1 });
      })
    );
  }

  @Action(CreateCategory)
  createGroup(ctx: any, action: CreateCategory) {
    const state = ctx.getState();

    return this.categoriesService.createCategory(action.payload).pipe(
      tap((response) => {
        const pagination: PaginationPayload = {
          page: 1,
          take: state.take
        };

        this.store.dispatch(new FetchCategories(pagination));

        ctx.patchState({ page: 1 });
      }
    ));
  }
}
