import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Apis } from 'src/app/auth/interfaces/apis';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserStateModel } from '../../state/user/user.state.model';
import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';
import Paginated from 'src/app/shared/interfaces/paginated';
@Injectable({
  providedIn: 'root'
})
export class AcceptUsersService {
  private apis: Apis = environment.apis;

  constructor(
    private http: HttpClient,
    private store: Store,

  ) {}

  acceptUser(username: string|undefined) {
    const url = `${this.apis.userApi.url}/${this.apis.userApi.resources.accept}`.replace(':username', <string>username);

    return this.http.post<void>(
      url,
      null
    );
  }

  getConfirmedUsers(
    paginationPayload: PaginationPayload | undefined = undefined,
    filter: string | undefined = undefined,
  ) : Observable<Paginated<UserStateModel>> {
    let paginationParams = paginationPayload ? Object.entries(paginationPayload).filter(([_, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) : {};

    const url = `${this.apis.userApi.url}/${this.apis.userApi.resources.list}`;

    return this.http.get<Paginated<UserStateModel>>(
      url,
      {
        params: {
          filter: 'Status::eq::CONFIRMED' + (filter ? `&&${filter}` : ''),
          ...paginationParams
        }
      }
    );
  }
}
