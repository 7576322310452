import PaginationPayload from 'src/app/shared/interfaces/payloads/pagination';
import { Question } from '../../interfaces/payloads/questions';

export class FetchQuestions {
  static readonly type = '[Questions] Fetch questions';
  constructor(public paginationPayload: PaginationPayload | undefined = undefined, public filter: string | undefined = undefined) {}
}

export class FetchQuestion {
  static readonly type = '[Questions] Fetch question';
  constructor(public questionId: number) {}
}

export class DeleteQuestion {
  static readonly type = '[Questions] Delete question';
  constructor(public questionId: number) {}
}

export class CreateQuestion {
  static readonly type = '[Questions] Create question';
  constructor(public payload: Question) {}
}
