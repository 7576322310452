import { Level } from "../interfaces/level";

export const LevelTextMap = [
    {
      level_id: Level.Beginner,
      levelText: 'Principiante'
    },
    {
      level_id: Level.Intermediate,
      levelText: 'Intermedio'
    },
    {
      level_id: Level.Advanced,
      levelText: 'Avanzado'
    }
];
