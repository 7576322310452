import { Selector, State } from "@ngxs/store";
import { AuthStateModel } from "./auth.state.model";
import { UserStateModel } from "src/app/user/state/user/user.state.model";
import { UserCourse } from "src/app/user/interfaces/payloads/user-course";
import { CourseUnitStateModel } from "src/app/courses/state/course-unit/course-unit.state.model";

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    id_token: undefined,
    groups: undefined,
    roles: undefined,
    user: {
      image_url: undefined,
      groups: undefined,
      username: undefined,
      name: undefined,
      last_name: undefined,
      roles: undefined,
      email: undefined,
      phone_number: undefined,
      status: undefined,
      courses: undefined,
      currentCourse: undefined
    }
  }
})
export class AuthSelectors {
  @Selector([AuthSelectors])
  static isAuthenticated(state: AuthStateModel) : boolean {
    return !!state.id_token;
  }

  @Selector([AuthSelectors])
  static idToken(state: AuthStateModel) : string|undefined {
    return state.id_token;
  }

  @Selector([AuthSelectors])
  static groups(state: AuthStateModel) : string[]|undefined {
    return state.groups;
  }

  @Selector([AuthSelectors])
  static roles(state: AuthStateModel) : string[]|undefined {
    return state.roles;
  }

  @Selector([AuthSelectors])
  static user(state: AuthStateModel) : UserStateModel {
    return state.user;
  }

  @Selector([AuthSelectors])
  static userImageUrl(state: AuthStateModel) : string|undefined {
    return state.user.image_url;
  }

  @Selector([AuthSelectors])
  static fullName(state: AuthStateModel) : string {
    return `${state.user.name ?? ''} ${state.user.last_name ?? ''}`;
  }

  @Selector([AuthSelectors])
  static courses(state: AuthStateModel) : UserCourse[] | undefined {
    return state.user.courses?.data;

  }
  @Selector([AuthSelectors])
  static course(state: AuthStateModel) : UserCourse | undefined {
    return state.user.courses?.data[0];
  }

  @Selector([AuthSelectors])
    static totalCourses(state: AuthStateModel): number | undefined {
      return state.user.courses?.total;
    }

  @Selector([AuthSelectors])
  static currentCourse(state: AuthStateModel): UserCourse | undefined {
    return state.user.currentCourse;
  }

  @Selector([AuthSelectors])
  static currentUnit(state: AuthStateModel): CourseUnitStateModel | undefined {
    return state.user.currentCourse?.current_unit;
  }

  @Selector([AuthSelectors])
  static isLastUnit(state: AuthStateModel): boolean {
    return state.user.currentCourse?.user_course_units
    .filter(user_course_unit => <number>user_course_unit.unit.order > <number>state.user.currentCourse?.current_unit.order)
    .sort((a, b) => <number>a.unit.order - <number>b.unit.order)
    .length === 0;
  }
}
