import { CourseUnitStateModel } from './../state/course-unit/course-unit.state.model';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngxs/store";
import { AuthSelectors } from "src/app/auth/state/auth/auth.state.selectors";
import { Apis } from "src/app/auth/interfaces/apis";
import { environment } from "src/environments/environment";
import { UnitCourse } from "../interfaces/payloads/unit-course";
import { Observable } from "rxjs";
import { UpdateUnit } from '../interfaces/payloads/update-unit';
import { UpdateVideo } from '../interfaces/payloads/update-video';


@Injectable({
  providedIn: "root"
})
export class UnitsService {
  private apis: Apis = environment.apis;
  constructor(
    private http: HttpClient,
    private store: Store) {}

  public getUnit(unitId: number): Observable<{ data: CourseUnitStateModel }> {
    return this.http.get<{ data: CourseUnitStateModel }>(
      `${this.apis.unitApi.url}/${this.apis.unitApi.resources.fetchUnit}`.replace(':unit_id', unitId.toString()));
  }
  public swapUnitOrder(unitId: number, swapUnitId: number): Observable<any> {
    return this.http.post<{ data: CourseUnitStateModel }>(
      `${this.apis.unitApi.url}/${this.apis.unitApi.resources.swapUnit}`.replace(':unit_id', unitId.toString()).replace(':swap_id', swapUnitId.toString()),
      {});
    }


  public updateUnitVideo(unitId: number, payload: UpdateVideo) : Observable<any> {
    const url: string = `${this.apis.unitApi.url}/${this.apis.unitApi.resources.updateVideo}`.replace(':id', unitId.toString());

    return this.http.put(
      url,
      payload
    );
  }

  public createUnit(unit: UnitCourse): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(`${this.apis.unitApi.url}/${this.apis.unitApi.resources.units}`, unit);
  }

  public updateUnit(unit: UpdateUnit): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(
      `${this.apis.unitApi.url}/${this.apis.unitApi.resources.editUnit}`,
      unit
    );
  }

  public uploadFile(file: File, unitId: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const url = `${this.apis.unitApi.url}/${this.apis.unitApi.resources.uploadFile}`.replace(':id', unitId.toString());

    return this.http.put(
      url,
      formData,
      {
        headers: {
          ContentType: 'multipart/form-data',
          Accept: file.type
        }
      }
    );
  }

  public deleteFile(unitId: number, fileId: number): Observable<any> {
    const url = `${this.apis.unitApi.url}/${this.apis.unitApi.resources.deleteFile}`.replace(':id', unitId.toString()).replace(':file_id', fileId.toString());

    return this.http.delete(url);
  }

  public deleteUnit(unitId: number): Observable<any> {
    const url = `${this.apis.unitApi.url}/${this.apis.unitApi.resources.deleteUnit}`.replace(':id', unitId.toString());

    return this.http.delete(url);
  }

  public downloadFile(unitId: number, fileId: number): Observable<Blob> {
    const url = `${this.apis.unitApi.url}/${this.apis.unitApi.resources.downloadFile}`.replace(':id', unitId.toString()).replace(':file_id', fileId.toString());

    return this.http.get(
      url,
      {
        headers: {
          Accept: 'application/pdf'
        },
        responseType: 'blob'
      }
    );
  }
}
