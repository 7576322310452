import { UserAssessmentsReportServiceService } from './../services/user-assessments-report-service/user-assessments-report-service.service';
import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import UserAssessmentsReportStateModel from "./user-assessments-report.state.model";
import { FetchUserAssessmentsReport } from './user-assessments-report.state.actions';
import { tap } from 'rxjs';

@State<UserAssessmentsReportStateModel>({
  name: "userAssessmentsReport",
  defaults: {
    data: [],
  }
})
@Injectable()
export default class UserAssessmentsReportState {

  constructor(
    private userAssessmentsReportServiceService: UserAssessmentsReportServiceService
  ) {}

  @Action(FetchUserAssessmentsReport)
  fetchUserAssessmentsReport(ctx: StateContext<UserAssessmentsReportStateModel>) {
    return this.userAssessmentsReportServiceService.fetchReport().pipe(
      tap(data => {
        ctx.patchState(data);
      })
    );
  }
}
