import { State, Action, StateContext, Selector } from '@ngxs/store';
import { LoadingStateModel } from './loading.state.model';
import { HideLoading, ResetLoading, SetLoadingTimeout, ShowLoading } from './loading.state.actions';
import { LoadingService } from '../services/loading/loading.service';
import { Injectable } from '@angular/core';

@State<LoadingStateModel>({
  name: 'loading',
  defaults: {
    clientsCounter: 0,
    timeout: 30000
  }
})
@Injectable()
export class LoadingState {

  constructor(
    private loadingService: LoadingService
  ) { }

  @Action(ShowLoading)
  show(ctx: StateContext<LoadingStateModel>) {
    const state = ctx.getState();

    if (state.clientsCounter === 0) {
      this.loadingService.show(state.timeout);
    }

    ctx.patchState({
      clientsCounter: ++state.clientsCounter
    });
  }

  @Action(HideLoading)
  hide(ctx: StateContext<LoadingStateModel>) {
    const state = ctx.getState();

    if (state.clientsCounter === 1) {
      this.loadingService.hide();
    }

    ctx.patchState({
      clientsCounter: state.clientsCounter === 0 ? state.clientsCounter : --state.clientsCounter
    });
  }

  @Action(SetLoadingTimeout)
  setTimeout(ctx: StateContext<LoadingStateModel>, action: SetLoadingTimeout) {
    ctx.patchState({
      timeout: action.timeout
    });
  }

  @Action(ResetLoading)
  reset(ctx: StateContext<LoadingStateModel>) {
    ctx.patchState({
      clientsCounter: 0
    });
  }
}
